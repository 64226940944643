@font-face {
  font-family: Montserrat-SemiBold;
  src: url("Montserrat-SemiBold.71eb7418.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-Medium;
  src: url("Montserrat-Medium.736c400d.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-MediumItalic;
  src: url("Montserrat-MediumItalic.9dd148d2.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-Light;
  src: url("Montserrat-Light.24ca0076.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("Montserrat-Bold.f47ec530.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("Montserrat-Regular.a50d8eb2.ttf") format("truetype");
}

/*# sourceMappingURL=index.ed1f7066.css.map */
